.scan-header {
  box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.15);

  .ant-page-header {
    padding: 5px 24px !important;
  }
}

.main-actions {
  margin-top: 2px;

  .main-search {
    max-width: 400px;
  }

  .main-action-btn {
    min-width: 210px;
  }
}

.main-form {
  .ant-form-item {
    margin: 0;
  }
  .ant-form-vertical .ant-form-item-label {
    padding: 0 4px;
  }
}