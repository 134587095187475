html {
  font-family: Calibri, Arial, Helvetica, sans-serif;
  font-size: 11pt;
  background-color: white;
}
a.comment-indicator:hover + div.comment {
  background: #ffd;
  position: absolute;
  display: block;
  border: 1px solid black;
  padding: 0.5em;
}
a.comment-indicator {
  background: red;
  display: inline-block;
  border: 1px solid black;
  width: 0.5em;
  height: 0.5em;
}
div.comment {
  display: none;
}
table {
  border-collapse: collapse;
  page-break-after: always; 
  margin-bottom: 1rem;
}
.gridlines td {
  /* border: 1px dotted black; */
}
.gridlines th {
  border: 1px dotted black;
}
.b {
  text-align: center;
}
.e {
  text-align: center;
}
.f {
  text-align: right;
}
.inlineStr {
  text-align: left;
}
.n {
  text-align: right;
}
.s {
  text-align: left;
}
td.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
th.style0 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
td.style1 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 12pt;
  background-color: white;
}
th.style1 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 12pt;
  background-color: white;
}
td.style2 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 9pt;
  background-color: white;
}
td.style-mini-size {
  font-size: 5pt;
}
th.style2 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 9pt;
  background-color: white;
}
td.style3 {
  vertical-align: top;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 9pt;
  background-color: white;
}
th.style3 {
  vertical-align: top;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 9pt;
  background-color: white;
}
td.style4 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 9pt;
  background-color: white;
}
th.style4 {
  vertical-align: bottom;
  border-bottom: none #000000;
  border-top: none #000000;
  border-left: none #000000;
  border-right: none #000000;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 9pt;
  background-color: white;
}
td.style5 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
th.style5 {
  vertical-align: middle;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  color: #000000;
  font-family: "Calibri";
  font-size: 11pt;
  background-color: white;
}
td.style6 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 9pt;
  background-color: white;
}
th.style6 {
  vertical-align: bottom;
  text-align: center;
  border-bottom: 1px solid #000000 !important;
  border-top: 1px solid #000000 !important;
  border-left: 1px solid #000000 !important;
  border-right: 1px solid #000000 !important;
  font-weight: bold;
  color: #000000;
  font-family: "Times New Roman";
  font-size: 9pt;
  background-color: white;
}
table.sheet0 thead th{
  border: none;
}
table.sheet0 thead .col0 {
  width: 38.63333289pt;
}
table.sheet0 thead .col1 {
  width: 28.46666634pt;
}
table.sheet0 thead .col2 {
  width: 175.54444243pt;
}
table.sheet0 thead .col3 {
  width: 28.46666634pt;
}
table.sheet0 thead .col4 {
  width: 94.8888878pt;
}
table.sheet0 thead .col5 {
  width: 56.93333268pt;
}
table.sheet0 thead .col6 {
  width: 56.93333268pt;
}
table.sheet0 tr {
  height: 15pt;
}
table.sheet0 tr.row9 {
  height: 15pt;
}