.main {
  width: 100%;
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1.5rem;
  row-gap: .5rem;
  justify-content: center;
  align-items: center;

  .btn {
    width: 50%;
    max-width: 400px;
    min-width: 120px;
    height: 200px;
    border-radius: 6px;
    font-size: 32px;
    font-weight: 600;
  }

  .btn-auto{
    background-color: rgba(185, 233, 13, 0.281);
  }
}